$enable-smooth-scroll: false;
@charset "utf-8";
@import "./variables.scss";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "./_icons.scss";

.social-title {
    color: #B0B0B0;
}

.mobile-menu-social__social-title {
    margin-bottom: 3px;
    
}

.footer-social__social-title {
    margin-bottom: 3px;
}
