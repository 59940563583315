@charset "utf-8";

.header-social {
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
    }
}

.header-social__social-icon {
    margin-right: 19px;
}

.mobile-menu-social__social-icon {
    margin: 0 5px 5px 0;
}

.footer-social__social-icon {
    margin: 0 3px;

    @include media-breakpoint-up(lg) {
        margin: 0 7px 0 0;
    }
}

.social-icon {
    display: inline-block;
    vertical-align: middle;
    width: 37px;
    height: 37px;
}

.social-icon_animated {
    
    &:hover {
        transition: all 0.5s ease;
        transform: rotate(360deg);
    }
}

.social-icon_size_sm {
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
}

.social-icon_ok {
    background: url("./../svg/icon-social-ok.svg") no-repeat;

    &:hover {
        background: url("./../svg/icon-social-ok-hover.svg") no-repeat;
    }
}

.social-icon_rutube {
    background: url("./../svg/icon-social-rutube.svg") no-repeat;

    &:hover {
        background: url("./../svg/icon-social-rutube-hover.svg") no-repeat;
    }
}

.social-icon_telegram {
    background: url("./../svg/icon-social-telegram.svg") no-repeat;

    &:hover {
        background: url("./../svg/icon-social-telegram-hover.svg") no-repeat;
    }
}


.social-icon_vk {
    background: url("./../svg/icon-social-vk.svg") no-repeat;

    &:hover {
        background: url("./../svg/icon-social-vk-hover.svg") no-repeat;
    }
}

.social-icon_facebook-round-red {
    background: url("./../svg/icon-social-facebook-round-red.svg") no-repeat;
}

.social-icon_instagram-round-red {
    background: url("./../svg/icon-social-instagram-round-red.svg") no-repeat;
}

.social-icon_ok-round-red {
    background: url("./../svg/icon-social-ok-round-red.svg") no-repeat;
}

.social-icon_rutube-round-red {
    background: url("./../svg/icon-social-rutube-round-red.svg") no-repeat;
}

.social-icon_telegram-round-red {
    background: url("./../svg/icon-social-telegram-round-red.svg") no-repeat;
}

.social-icon_twitter-round-red {
    background: url("./../svg/icon-social-twitter-round-red.svg") no-repeat;
}

.social-icon_vimeo-round-red {
    background: url("./../svg/icon-social-vimeo-round-red.svg") no-repeat;
}

.social-icon_vk-round-red {
    background: url("./../svg/icon-social-vk-round-red.svg") no-repeat;
}


